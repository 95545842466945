/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainStoreEnumsSupportedWebPublishingPlatform {
    FxId = 0,
    VkontaktePlay = 1,
    Odnoklassniki = 2,
    VkontakteCom = 3,
    YandexGames = 4,
    AbsoluteGames = 5,
    Kongregate = 6,
    FacebookGames = 7,
    CrazyGames = 8,
    WizQ = 9,
    Telegram = 10,
    FxLauncher = 11,
    FxNative = 12,
    Unspecified = -1,
    NUMBER_unknown_default_open_api = 11184809
}


export function FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON(json: any): FxIdDomainStoreEnumsSupportedWebPublishingPlatform {
    return FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSONTyped(json, false);
}

export function FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainStoreEnumsSupportedWebPublishingPlatform {
    return json as FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
}

export function FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON(value?: FxIdDomainStoreEnumsSupportedWebPublishingPlatform | null): any {
    return value as any;
}

