import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth, OAuthProvider, GoogleAuthProvider, signOut, User, EmailAuthProvider, Auth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Props } from "react-firebaseui";

import { setAuthToken } from "./Auth";
import { FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest } from "./gen";
import OpenApiClient from "./OpenApiClient";
import i18next from "i18next";

const appleProvider = new OAuthProvider("apple.com");
appleProvider.setCustomParameters({
	locale: i18next.language
});

const uiConfig: Props["uiConfig"] = {
	signInFlow: "popup",
	callbacks: {
		// Avoid redirects after sign-in.
		signInSuccessWithAuthResult: () => false
	},
	signInOptions: [
		{ provider: GoogleAuthProvider.PROVIDER_ID, scopes: ["https://www.googleapis.com/auth/userinfo.email"] }
	]
};

if (import.meta.env.VITE_APP_ENV_OVERRIDE_NAME === "stage") {
	uiConfig.signInOptions?.push({ provider: appleProvider.providerId, scopes: ["email", "name"] });
	uiConfig.signInOptions?.push({
		provider: EmailAuthProvider.PROVIDER_ID,
		signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
	});
}

// Initialize Firebase
let app: FirebaseApp | undefined;
let auth: Auth | undefined;

const firebaseInit = (firebaseConfig: FirebaseOptions) => {
	if (app && auth) return { app, auth };

	app = initializeApp(firebaseConfig);
	auth = getAuth(app);
	return { app, auth };
};

const loginWithFirebaseUser = async (game: string, user: User, marketingEnabled: boolean | undefined) => {
	const data: FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest = {
		GameSystemName: game,
		Token: await user.getIdToken(),
		MarketingEnabled: marketingEnabled
	};

	try {
		const { Token } = await OpenApiClient.Auth.fxIdWebFeaturesAuthAuthenticateFromFirebaseEndpoint(data);
		setAuthToken(Token);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		throw err;
	}
};

const signOutFromFirebase = async () => {
	if (!auth) {
		throw new Error("Firebase app is not initialized! Call firebaseInit() first");
	}
	return signOut(auth);
};

const useFirebaseUser = () => {
	if (!auth) {
		throw new Error("Firebase app is not initialized! Call firebaseInit() first");
	}
	const [firebaseUser, setFirebaseUser] = useState<User | null>();

	// Listen to the Firebase Auth state and set the local state.
	useEffect(() => {
		if (!auth) return;
		const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
			setFirebaseUser(user);
		});
		return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
	}, []);
	return firebaseUser;
};

export {
	uiConfig,
	auth,
	app as firebaseApp,
	loginWithFirebaseUser,
	useFirebaseUser,
	signOutFromFirebase,
	firebaseInit
};
