/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptions,
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptions';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptions;

export function FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptionsFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdDtoSteamLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdSteamLoginOptions);
    }

    return {};
}

