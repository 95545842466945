/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokens,
    instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokens,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokensFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokensFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokensToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokens';

/**
 * @type FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens = FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokens;

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokensFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokensFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokensFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokensFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokensToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokens(value)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokensToJSON(value as FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericTokens);
    }

    return {};
}

