import { FxIdWebFeaturesGamesChooseGameRequest, FxIdWebFeaturesGamesChooseGameResponse } from "@app/Api/gen";
import OpenApiClient from "@app/Api/OpenApiClient";
import { produce } from "immer";
import create from "zustand"; // Исправленный импорт

export interface IWebPlayerStore {
	chooseGameResponse: FxIdWebFeaturesGamesChooseGameResponse | null;
	fetchChosenGame: (payload: FxIdWebFeaturesGamesChooseGameRequest) => Promise<void>;

	loading: boolean;
	error: string | null;
}

export const webPlayerStore = create<IWebPlayerStore>((set) => ({
	chooseGameResponse: null,
	loading: false,
	error: null,

	fetchChosenGame: async (payload: FxIdWebFeaturesGamesChooseGameRequest) => {
		set(
			produce((store: IWebPlayerStore) => {
				store.loading = true;
				store.error = null;
			})
		);

		try {
			const response = await OpenApiClient.Play.fxIdWebFeaturesGamesChooseGameEndpoint({
				GameSystemName: payload.GameSystemName,
				OriginUrl: payload.OriginUrl
			});
			set(
				produce((store: IWebPlayerStore) => {
					store.chooseGameResponse = response;
				})
			);
		} catch (error: any) {
			// Изменена типизация ошибки
			set(
				produce((store: IWebPlayerStore) => {
					store.error = error.message || "Unknown error"; // Обработка ошибки
				})
			);
		} finally {
			set(
				produce((store: IWebPlayerStore) => {
					store.loading = false;
				})
			);
		}
	}
}));
