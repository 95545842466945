export const MethodGetInfo = "GetInfo" as const;
export const MethodSocialSettings = "SocialSettings" as const;
export const MethodBuyProduct = "BuyProduct" as const;
export const MethodDefaultStoreFront = "DefaultStoreFront" as const;
export const MethodOpenModal = "OpenModal" as const;
export const MethodChangeLanguage = "ChangeLanguage" as const;
export const MethodCreateSupportRequest = "CreateSupportRequest" as const;
export const MethodAuthenticateUser = "AuthenticateUser" as const;
export const MethodPushRegister = "PushRegister" as const;
export const MethodGetUrlHash = "GetUrlHash" as const;
export const MethodSetUrlHash = "SetUrlHash" as const;
export const MethodShowLinkShareModal = "ShowLinkShareModal" as const;
export const MethodSetStatus = "SetStatus" as const;
export const MethodAdsIsVideoReady = "AdsIsVideoReady" as const;
export const MethodAdsShowVideo = "AdsShowVideo" as const;
export const MethodAdsStickyBannerShow = "AdsStickyBannerShow" as const;
export const MethodAdsStickyBannerHide = "AdsStickyBannerHide" as const;
export const MethodStatInitialize = "StatInitialize" as const;
export const MethodStatEvent = "StatEvent" as const;
export const MethodGetFriends = "GetFriends" as const;
export const MethodInviteFriends = "InviteFriends" as const;
export const MethodJoinCommunity = "JoinCommunity" as const;
export const MethodAddToFavorites = "AddToFavorites" as const;
export const MethodGoogleTagManagerPushToDataLayer = "GoogleTagManagerPushToDataLayer" as const;
export const MethodSendVkBridge = "SendVkBridge" as const;
export const MethodSendCrazyGamesSdk = "SendCrazyGamesSdk" as const;
export const MethodWordsFilter = "WordsFilter" as const;
export const MethodConsentManagerStatus = "ConsentManagerStatus" as const;
export const MethodSendFxEvent = "SendFxEvent" as const;

// Это старое событие. Нельзя менять константу
export const EventChangeLanguage = "ChangeLanguage" as const;
// Это старое событие. Нельзя менять константу
export const EventCreateSupportRequest = "CreateSupportRequest" as const;
export const EventAdsVideoAvailable = "EventAdsVideoAvailable" as const;
export const EventConsentManagerStatus = "EventConsentManagerStatus" as const;
export const EventAdsFinished = "EventAdsFinished" as const;
export const EventAdsFailed = "EventAdsFailed" as const;
export const EventAdsSkipped = "EventAdsSkipped" as const;
export const EventFocusChanged = "EventFocusChanged" as const;
export const EventOnChangeUserInfo = "EventOnChangeUserInfo" as const;
export const EventOnChangeOrientation = "EventOnChangeOrientation" as const;

// Общение с айфреймом
export const MessageFxIdInitializePort = "FxIdSdk::InitializePort" as const;
export const MessageFxIdPort = "FxIdSdk::Port" as const;

export type EventName =
	| typeof EventChangeLanguage
	| typeof EventCreateSupportRequest
	| typeof EventAdsVideoAvailable
	| typeof EventAdsFinished
	| typeof EventAdsSkipped
	| typeof EventFocusChanged
	| typeof EventAdsFailed
	| typeof EventOnChangeUserInfo
	| typeof EventOnChangeOrientation
	| typeof EventConsentManagerStatus;

export type MethodName =
	| typeof MethodGetInfo
	| typeof MethodSocialSettings
	| typeof MethodBuyProduct
	| typeof MethodDefaultStoreFront
	| typeof MethodChangeLanguage
	| typeof MethodAuthenticateUser
	| typeof MethodCreateSupportRequest
	| typeof MethodPushRegister
	| typeof MethodOpenModal
	| typeof MethodGetUrlHash
	| typeof MethodSetUrlHash
	| typeof MethodShowLinkShareModal
	| typeof MethodSetStatus
	| typeof MethodAdsIsVideoReady
	| typeof MethodAdsShowVideo
	| typeof MethodAdsStickyBannerShow
	| typeof MethodAdsStickyBannerHide
	| typeof MethodStatInitialize
	| typeof MethodStatEvent
	| typeof MethodGetFriends
	| typeof MethodInviteFriends
	| typeof MethodJoinCommunity
	| typeof MethodAddToFavorites
	| typeof MethodGoogleTagManagerPushToDataLayer
	| typeof MethodSendCrazyGamesSdk
	| typeof MethodSendVkBridge
	| typeof MethodWordsFilter
	| typeof MethodConsentManagerStatus
	| typeof MethodSendFxEvent;

export interface FxIdMessage {
	method: MethodName | EventName;
	params: unknown[];
	result?: unknown;
	error?: string;
}
