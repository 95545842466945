import { getAuthToken } from "./Auth";
import {
	Configuration,
	InviteApi,
	AuthApi,
	PlayApi,
	ProfileApi,
	StoreApi,
	YandexgamesApi,
	PublicGameConfigApi,
	FriendsApi,
	GoApi,
	Bank131Api,
	WizqApi,
	FtlIntegrationApi,
	GamesApi,
	InfoApi
} from "./gen";

export const pathname =
	import.meta.env.BASE_URL !== "/" && import.meta.env.BASE_URL !== "./" ? import.meta.env.BASE_URL : "";

export const basePath = import.meta.env.VITE_APP_API_BASE_PATH
	? import.meta.env.VITE_APP_API_BASE_PATH
	: `${window.location.origin}${pathname}`;

export const openApiConfig: Configuration = new Configuration({
	basePath,
	accessToken: () => getAuthToken() as string
});

const OpenApiClient = {
	Invite: new InviteApi(openApiConfig),
	Auth: new AuthApi(openApiConfig),
	Games: new GamesApi(openApiConfig),
	Play: new PlayApi(openApiConfig),
	Profile: new ProfileApi(openApiConfig),
	Store: new StoreApi(openApiConfig),
	Info: new InfoApi(openApiConfig),
	YandexGames: new YandexgamesApi(openApiConfig),
	PublicGameConfigApi: new PublicGameConfigApi(openApiConfig),
	Friends: new FriendsApi(openApiConfig),
	Go: new GoApi(openApiConfig),
	Bank131: new Bank131Api(openApiConfig),
	WizQ: new WizqApi(openApiConfig),
	FtlIntegration: new FtlIntegrationApi(openApiConfig)
};

export default OpenApiClient;
