/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateWithExternalIdState } from './FxIdApplicationHandlersAuthAuthenticateWithExternalIdState';
import {
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSON,
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateWithExternalIdState';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokensFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokensFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokensToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptions } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptions';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptionsFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptionsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptionsToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptions';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse {
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateWithExternalIdState}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse
     */
    State: FxIdApplicationHandlersAuthAuthenticateWithExternalIdState;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse
     */
    Tokens?: FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokens | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse
     */
    Hint: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse
     */
    Email?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptions}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse
     */
    TransferOptions?: FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptions | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "State" in value;
    isInstance = isInstance && "Hint" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'State': FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSON(json['State']),
        'Tokens': !exists(json, 'Tokens') ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokensFromJSON(json['Tokens']),
        'Hint': json['Hint'],
        'Email': !exists(json, 'Email') ? undefined : json['Email'],
        'TransferOptions': !exists(json, 'TransferOptions') ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptionsFromJSON(json['TransferOptions']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'State': FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSON(value.State),
        'Tokens': FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTokensToJSON(value.Tokens),
        'Hint': value.Hint,
        'Email': value.Email,
        'TransferOptions': FxIdWebFeaturesAuthAuthenticateWithExternalIdWithGenericResponseTransferOptionsToJSON(value.TransferOptions),
    };
}

