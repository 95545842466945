import "./index.css";
import "./index.scss";

import React from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./dayjs";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { getGoogleTagManagerTagData } from "./Utils/Analytics";
import * as Sentry from "@sentry/react";
import { BrowserOptions } from "@sentry/browser/types-ts3.8/client";
import OpenApiClient from "./Api/OpenApiClient";

const basepath =
	import.meta.env.VITE_APP_BUILD_MODE !== "standalone"
		? import.meta.env.VITE_APP_ROUTER_BASEPATH ?? import.meta.env.BASE_URL
		: undefined;

console.log(`Environment: %o`, import.meta.env);
console.log(`BASE_URL: %s`, import.meta.env.BASE_URL);
console.log(`Standalone: %s`, import.meta.env.VITE_APP_BUILD_MODE);
console.log(`import.meta.env.VITE_APP_ROUTER_BASEPATH: %s`, import.meta.env.VITE_APP_ROUTER_BASEPATH);
console.log(`basepath: %s`, basepath);
console.log(`BuildId: %s`, import.meta.env.VITE_APP_BUILD_ID);

const sentryUrl =
	import.meta.env.BASE_URL == null ||
	import.meta.env.BASE_URL === "/" ||
	import.meta.env.BASE_URL === "" ||
	import.meta.env.BASE_URL === "./"
		? "/api/s_tunnel"
		: import.meta.env.BASE_URL.startsWith("http")
		? new URL("/api/s_tunnel", import.meta.env.BASE_URL).toString()
		: new URL("/api/s_tunnel", new URL(import.meta.env.BASE_URL, window.location.origin).toString()).toString();

const googleTagManagerId = getGoogleTagManagerTagData();

if (googleTagManagerId != null) {
	console.log("Initializing google tag with id: %o", googleTagManagerId);

	const tagManagerArgs: TagManagerArgs = {
		gtmId: googleTagManagerId.tag
	};

	if (googleTagManagerId.env != null) {
		tagManagerArgs.auth = googleTagManagerId.env.auth;
		tagManagerArgs.preview = googleTagManagerId.env.preview;
	}

	TagManager.initialize(tagManagerArgs);

	window.dataLayer?.push("js", new Date());
}

const integrations: Sentry.BrowserOptions["integrations"] = [
	Sentry.browserTracingIntegration(),
	Sentry.replayIntegration({
		maskAllText: false,
		blockAllMedia: false
	})
];

// if (import.meta.env.VITE_APP_ENV_OVERRIDE_NAME !== "prod") {
// 	integrations.push(
// 		Sentry.feedbackIntegration({
// 			colorScheme: "dark",
// 			showName: false
// 		})
// 	);
// }

Sentry.init({
	dsn: "https://7cb05911025b9bc5fc5c5182ca0542d4@sentry.fxgam.es/4",
	tunnel: sentryUrl,
	integrations: integrations,
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: import.meta.env.VITE_APP_ENV_OVERRIDE_NAME === "prod" ? 0.1 : 100, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Функция, которая предотвращает вызов контекстного меню
function disableContextMenu(event: MouseEvent) {
	event.preventDefault();
}

// Назначение обработчика события на весь документ и окно
document.addEventListener("contextmenu", disableContextMenu);
window.addEventListener("contextmenu", disableContextMenu);

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const Router = import.meta.env.VITE_APP_BUILD_MODE === "standalone" ? HashRouter : BrowserRouter;

root.render(
	<Router basename={basepath}>
		<App />
	</Router>
);

if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register(import.meta.env.MODE === "production" ? "/service-worker.js" : "/dev-sw.js?dev-sw", {
			type: import.meta.env.MODE === "production" ? "classic" : "module"
		})
		.then(console.log)
		.catch(console.error);
}
